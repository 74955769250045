<template>
  <q-layout view="hhr lpr ffr" >
    <q-header  :reveal="isMobile()">
      <q-toolbar>

        <img class="logo-header" :src="'/assets/imagenes/logos/' + (isMobile() ? 'logo-dorado.png' : 'logo-dorado-blanco.png')" alt="Logo de Lallez">
        <q-space></q-space>
        <q-tabs dense v-model="tab" shrink :mobile-arrows="false" class="gt-sm menu-lallez" indicator-color="lallez-gold"
          active-class="text-lallez-black bg-lallez-gold">
          <q-route-tab href="#inicio" name="inicio" label="Inicio" @click.prevent="onTabClick('inicio')" class="text-slim" />
          <q-route-tab href="#nosotros" name="nosotros" label="Nosotros" @click.prevent="onTabClick('nosotros')" class="text-slim" />
          <q-route-tab href="#servicios" name="servicios" label="Servicios" @click.prevent="onTabClick('servicios')" class="text-slim" />
          <q-route-tab href="#contacto" name="contacto" label="Contacto" @click.prevent="onTabClick('contacto')" class="text-slim" />
        </q-tabs>
        <q-btn flat dense round @click="leftDrawerOpen = !leftDrawerOpen" aria-label="Menu" icon="mdi-menu" class="lt-md" />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen" show-if-above class="bg-lallez-black" side="right" overlay behavior="mobile" elevated >
      <q-list>
        <q-item clickable tag="a" href="#inicio" @click.prevent="onTabClick('inicio')">
          <q-item-section class="bg-lallez-gold text-bold text-center text-lallez-black" style="border-radius: 5px;">
            <q-item-label>Inicio</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" href="#nosotros" @click.prevent="onTabClick('nosotros')">
          <q-item-section class="bg-lallez-gold text-bold text-center text-lallez-black" style="border-radius: 5px;">
            <q-item-label>Nosotros</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" href="#servicios" @click.prevent="onTabClick('servicios')">
          <q-item-section class="bg-lallez-gold text-bold text-center text-lallez-black" style="border-radius: 5px;">
            <q-item-label>Servicios</q-item-label>
          </q-item-section>
        </q-item>
        <q-item clickable tag="a" href="#contacto" @click.prevent="onTabClick('contacto')">
          <q-item-section class="bg-lallez-gold text-bold text-center text-lallez-black" style="border-radius: 5px;">
            <q-item-label>Contacto</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-page-container >
      
      <q-page id="q-page" :style-fn="(o, h) => {
          return {minHeight: (h)+'px'}
        }">
        <div id="inicio" class="row justify-center items-center">
          <div class="text-white col-9 text-center" style="z-index:1;">
            <p class="text-h4 text-bold q-ma-none text-center">LLEVANDO TUS FINANZAS</p>
            <p class="text-h4 text-weight-light text-center">A OTRO NIVEL</p>
            <q-btn @click.prevent="onTabClick('contacto')" class="bg-lallez-gold text-lallez-black text-bold">CONTÁCTANOS</q-btn>
          </div>
          
          
          

        </div>
        <q-tab-panels
          :style="(isMobile() ? 'min-height:' : 'height:') + parentHeight + ';'"
          id="tab-nosotros"
          v-model="panel"
          animated
          infinite
          transition-prev="slide-left"
          transition-next="slide-right"
        >
          <q-tab-panel name="nosotros" class="q-pa-none panel-nosotros">
            <div id="nosotros" class="row items-center q-py-xl tab-nosotros-content">
              <div class="col-6 gt-sm">
                <q-img src="/assets/imagenes/hombre-negocios-financiero-inspector-secretaria-haciendo-informe-calculo-o-comprobacion-balance-inspector-servicio-rentas-internas-revisando-documento-concepto-auditoria.jpg"></q-img>
              </div>
              <div class="col-12 col-md-6 items-center q-pa-xl withou-padding-xs">
                <p class="text-h4 text-lallez-gold text-bold">¿QUIÉNES SOMOS?</p>
                <p class="text-white text-slim">Somos una consultoría independiente dedicada al impulso estratégico y el desarrollo sostenible de empresas de todos los tamaños. Nuestra misión es fortalecer y estructurar la viabilidad financiera de tu negocio, proporcionando un modelaje financiero experto para alcanzar tus objetivos de crecimiento y prosperidad empresarial.</p>
                <q-btn @click="panel = 'nosotros-1'" class="bg-lallez-gold text-lallez-black text-bold">CONOCE MÁS</q-btn>
              </div>
              <div class="col-12 lt-md withou-padding-xs">
                <q-img src="/assets/imagenes/hombre-negocios-financiero-inspector-secretaria-haciendo-informe-calculo-o-comprobacion-balance-inspector-servicio-rentas-internas-revisando-documento-concepto-auditoria.jpg"></q-img>
              </div>
            </div>
          </q-tab-panel>

          <q-tab-panel name="nosotros-1" class="q-pa-none panel-nosotros">
            <div id="nosotros-1" class="row items-center q-py-xl tab-nosotros-content">
              
              <div class="col-12 col-md-6 items-center q-pa-xl withou-padding-xs">
                <p class="text-white text-slim">Lallez es una firma privada de asesores con más de 25 años de experiencia en el sector financiero y banca, ofreciendo servicios financieros en todos los sectores económicos.</p>
                <p class="text-white text-slim">Contamos con una excelente formación académica, conocimiento del mercado y sectores económicos en México, experiencia, red de contactos, equipo especializado que ponemos a su disposición para el logro de sus objetivos financieros.</p>
                <q-btn @click="panel = 'nosotros'" class="bg-lallez-gold text-lallez-black text-bold">¿QUIÉNES SOMOS?</q-btn>
              </div>
              <div class="col-12 col-md-6 withou-padding-xs">
                <q-img src="/assets/imagenes/hombre-negocios-financiero-inspector-secretaria-haciendo-informe-calculo-o-comprobacion-balance-inspector-servicio-rentas-internas-revisando-documento-concepto-auditoria.jpg"></q-img>
              </div>
            </div>
          </q-tab-panel>

        </q-tab-panels>
        
        <div id="nosotros-2" class="row justify-center text-center q-py-xl">
          <div class="col-12 col-md-3 q-pa-md ">
            <q-img class="gt-sm" fit="none" width="200px" height="200px" src="/assets/iconos/svg/mision.svg"></q-img>
            <q-img class="lt-md" fit="cover" width="200px" height="200px" src="/assets/iconos/svg/mision.svg"></q-img>
            <p class="text-h5 text-lallez-black text-bold q-ma-none">MISIÓN</p>
            <q-separator style="width: 20px;margin-left: auto;margin-right: auto;" size="3px" color="lallez-gold" spaced="10px"></q-separator>
            <p class="text-lallez-black text-slim">Nuestra misión es impulsar el éxito empresarial a través de soluciones financieras innovadoras y personalizadas, que fortalezcan y optimicen el desempeño de las empresas, independientemente de su tamaño.</p>
          </div>
          <div class="col-12 col-md-3 q-pa-md relative-position">
            <q-separator class="absolute-top lt-md" style="margin:auto;width: 80%;" size="3px" color="lallez-gold"></q-separator>
            <q-separator class="absolute-left gt-sm" vertical style="height: 130px;margin-top: 50px;" size="3px" color="lallez-gold"></q-separator>
            <q-separator class="absolute-right gt-sm" vertical style="height: 130px;margin-top: 50px;" size="3px" color="lallez-gold"></q-separator>
            <q-separator class="absolute-bottom lt-md" style="margin:auto;width: 80%;" size="3px" color="lallez-gold"></q-separator>
            <q-img class="gt-sm" fit="none" width="200px" height="200px" src="/assets/iconos/svg/vision.svg"></q-img>
            <q-img class="lt-md" fit="cover" width="200px" height="200px" src="/assets/iconos/svg/vision.svg"></q-img>
            <p class="text-h5 text-lallez-black text-bold q-ma-none">VISIÓN</p>
            <q-separator style="width: 20px;margin-left: auto;margin-right: auto;" size="3px" color="lallez-gold" spaced="10px"></q-separator>
            <p class="text-lallez-black text-slim">Nos visualizamos como líderes en la transformación financiera, siendo reconocidos por nuestra excelencia en el asesoramiento estratégico y nuestro compromiso inquebrantable con el crecimiento sostenible de nuestros clientes.</p>
          </div>
          <div class="col-12 col-md-3 q-pa-md">
            <q-img class="gt-sm" fit="none" width="200px" height="200px" src="/assets/iconos/svg/objetivo.svg"></q-img>
            <q-img class="lt-md" fit="cover" width="200px" height="200px" src="/assets/iconos/svg/objetivo.svg"></q-img>
            <p class="text-h5 text-lallez-black text-bold q-ma-none">OBJETIVO</p>
            <q-separator style="width: 20px;margin-left: auto;margin-right: auto;" size="3px" color="lallez-gold" spaced="10px"></q-separator>
            <p class="text-lallez-black text-slim">Nuestro objetivo es ser el socio estratégico preferido de las empresas, ofreciendo servicios financieros de primera clase que catalicen su desarrollo y les permitan alcanzar nuevas alturas de éxito y rentabilidad.</p>
          </div>
        </div>

        <div id="nosotros-3" class="row justify-center items-center">
          <div class="col-11 col-md-9 text-center z-top">
            <p class="text-h5 text-white q-ma-none">TE BRINDAMOS</p>
            <p class="text-h5 text-white text-bold q-ma-none">LA ASESORÍA FINANCIERA QUE NECESITAS</p>
            <q-btn @click.prevent="onTabClick('contacto')" class="bg-lallez-gold text-lallez-black q-mt-md text-bold">CONTÁCTANOS</q-btn>
          </div>
        </div>

        <div id="servicios" class="row justify-center bg-white q-py-xl">
          <div class="col-12 q-py-xl">
            <div class="row justify-center">
              <div class="col-10 col-md-4 text-center">
                <p class="text-h4 text-lallez-gold text-bold q-mb-sm">NUESTROS SERVICIOS</p>
                <p class=" text-lallez-black q-pb-xl text-slim">Ofrecemos asesoría financiera ajustada a las 
                  necesidades de nuestros clientes, brindando siempre 
                  un servicio de calidad y la mejor atención.</p>
              </div>
            </div>
            <div class="row justify-center q-gutter-lg">
              <div v-for="service in services" :key="service.id" class="col-md-4 col-10">
                <q-img fit="cover" position="0 0" :src="service.img" class="service-img" :alt="service.alt">
                  <div class="absolute-full flex flex-center service-title" :title="service.alt">
                    <span class="self-center row justify-center full-width full-height items-center text-h6 text-bold text-white" v-html="service.title" >
                    </span>
                  </div>
                </q-img>
              </div>
            </div>
          </div>
        </div>

        <div id="contacto" class="row justify-center items-center q-gutter-sm q-py-xl">
          <div class="col-md-4 col-10 z-top q-py-xl">
            <p class="text-h5 text-lallez-gold text-bold">CONTÁCTANOS</p>
            <q-form @submit="onSubmit()" class="q-gutter-md text-white">
              <q-input
                input-class="text-white"  
                color="white"
                label-color="white"
                dense
                v-model="contact.name"
                label="Nombre"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Por favor, escriba su nombre']"
                no-error-icon
              />
              <div class="row">
                <div class="col">
                  <q-input
                    input-class="text-white"  
                    color="white"
                    label-color="white"
                    dense
                    v-model="contact.email"
                    type="email"
                    label="Correo"
                    lazy-rules
                    :rules="[ 
                      val => val && val.length > 0 || 'Por favor, escriba su correo',
                      val => val && validateEmail(val) || 'Escriba un correo valido'
                    ]"
                    no-error-icon
                  />
                </div>
                <div class="col q-ml-md">
                  <q-input
                    input-class="text-white"  
                    color="white"
                    label-color="white"
                    dense
                    v-model="contact.phone"
                    label="Teléfono"
                    type="tel"
                    lazy-rules
                    :rules="[ 
                      val => val && val.length > 0 || 'Por favor, escriba su teléfono',
                      val => val && validatePhone(val) || 'Escriba un teléfono valido',
                      val => val && val.length == 10 || 'El teléfono debe tener 10 digitos',
                    ]"
                    no-error-icon
                  />
                </div>
              </div>
              
              <q-input
                input-class="text-white"  
                color="white"
                label-color="white"
                dense
                v-model="contact.subject"
                label="Asunto"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Por favor, escriba el asunto']"
                no-error-icon
              />
              <q-input
                input-class="text-white"  
                color="white"
                label-color="white"
                dense
                v-model="contact.message"
                type="textarea"
                label="Mensaje"
                lazy-rules
                :rules="[ val => val && val.length > 0 || 'Por favor, escriba el mensaje']"
                no-error-icon
              />
              <q-btn type="submit" :loading="sendLoading" class="bg-lallez-gold text-lallez-black q-mt-md text-bold" label="ENVIAR"/>
            </q-form>
          </div>
          <div class="col-md-4 col-10 z-top q-py-xl">
            <div class="row " :class="{'q-pl-xl': !isMobile()}" >
              <div class="col-3">
                <q-img class="img-contacto" src="/assets/iconos/svg/experiencia.svg"></q-img>
              </div>
              <div class="col-9 q-pl-md">
                <p class="text-h5 text-white text-bold q-ma-none">EXPERIENCIA</p>
                <p class="text-white text-slim">
                  Más de 25 años de experiencia en el mercado local y global.
                </p>
                <q-separator style="width: 50px;" size="3px" color="lallez-gold" spaced="25px"></q-separator>
              </div>
            </div>
            <div class="row " :class="{'q-pl-xl': !isMobile()}">
              <div class="col-3">
                <q-img class="img-contacto" src="/assets/iconos/svg/profesionalismo.svg"></q-img>
              </div>
              <div class="col-9 q-pl-md">
                <p class="text-h5 text-white text-bold q-ma-none">PROFESIONALISMO</p>
                <p class="text-white text-slim">
                  Grupo de ﬁnancieros con un alto conocimiento del mercado.
                </p>
                <q-separator style="width: 50px;" size="3px" color="lallez-gold" spaced="25px"></q-separator>
              </div>
            </div>
            <div class="row " :class="{'q-pl-xl': !isMobile()}">
              <div class="col-3">
                <q-img class="img-contacto" src="/assets/iconos/svg/seguridad.svg"></q-img>
              </div>
              <div class="col-9 q-pl-md">
                <p class="text-h5 text-white text-bold q-ma-none">SEGURIDAD</p>
                <p class="text-white text-slim">
                  Equipo conformado por expertos para brindarle a los clientes la seguridad que merecen.
                </p>
                <q-separator class="gt-sm" style="width: 50px;" size="3px" color="lallez-gold" spaced="25px"></q-separator>
              </div>
            
            </div>
          </div>
        </div>

        <div id="footer" class="row justify-center items-center q-py-xl bg-lallez-black text-footer">
          <div class="col-10 col-md-7 ">
            <div class="row q-gutter-x-lg q-gutter-y-sm">
              <div class="col-12 col-md-4 q-pr-xl">
                <img width="130" src="/assets/imagenes/logos/logo-dorado-blanco.png" alt="Logo de Lallez">
                <p class="text-white ">
                  Buscamos el crecimiento y beneﬁcio de nuestros clientes brindando asesoría ﬁnanciera y de negocios.
                </p>
              </div>
              <div class="col-4 col-md">
                <p class="text-lallez-gold text-bold">
                  LÁLLEZ
                </p>
                <ul class="text-white links-footer q-pl-md">
                  <li>
                    <q-btn flat unelevated dense @click.prevent="onTabClick('inicio')" class="text-white text-footer text-weight-regular" no-caps label="Inicio" type="a" href="#inicio" ></q-btn>
                  </li>
                  <li>
                    <q-btn flat unelevated dense @click.prevent="onTabClick('nosotros')" class="text-white text-footer text-weight-regular" no-caps label="Nosotros" type="a" href="#nosotros" ></q-btn>
                  </li>
                  <li>
                    <q-btn flat unelevated dense @click.prevent="onTabClick('servicios')" class="text-white text-footer text-weight-regular" no-caps label="Servicios" type="a" href="#servicios" ></q-btn>
                  </li>
                  <li>
                    <q-btn flat unelevated dense @click.prevent="onTabClick('contacto')" class="text-white text-footer text-weight-regular" no-caps label="Contacto" type="a" href="#contacto" ></q-btn>
                  </li>
                </ul>
              </div>
              <div class="col col-md-3 gt-sm">
                <p class="text-lallez-gold text-bold q-mt-none">
                  CONTÁCTANOS
                </p>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-email" />
                  <q-btn flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="lallez@gmail.com" type="a" href="mailto:lallez@gmail.com" ></q-btn>
                </div>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-phone" />
                  <q-btn flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="+52 999 172 5855" type="a" href="https://wa.me/529991725855?text=%C2%A1Hola%2C%20vengo%20de%20su%20p%C3%A1gina%20web%21%20Me%20interesa%20saber%20informaci%C3%B3n" target="_blank" ></q-btn>
                </div>
              </div>
              <div class="col-6 col-md">
                <p class="text-lallez-gold text-bold q-mt-none">
                  SÍGUENOS
                </p>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-facebook" />
                  <q-btn @click.prevent="false" flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="Facebook" type="a" href="https://www.facebook.com/share/enuPUjac86irRm6a/" target="_blank" ></q-btn>
                </div>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-instagram" />
                  <q-btn @click.prevent="false" flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="Instagram" type="a" href="https://www.instagram.com/grupo_lallez/" target="_blank" ></q-btn>
                </div>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-linkedin" />
                  <q-btn @click.prevent="false" flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="Linkedin" type="a" href="https://www.linkedin.com/lallez/" target="_blank" >
                    <q-tooltip
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      Estamos trabajando en las redes
                    </q-tooltip>
                  </q-btn>
                </div>
              </div>
              <div class="col lt-md">
                <p class="text-lallez-gold text-bold q-mt-none">
                  CONTÁCTANOS
                </p>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-email" />
                  <q-btn flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="lallez@gmail.com" type="a" href="mailto:lallez@gmail.com" ></q-btn>
                </div>
                <div class="row text-white items-center q-gutter-x-xs">
                  <q-icon size="xs" class="text-lallez-black bg-lallez-gold icon-footer" name="mdi-phone" />
                  <q-btn flat unelevated dense class="text-white text-footer text-weight-regular" no-caps label="+52 999 172 5855" type="a" href="https://wa.me/529991725855?text=%C2%A1Hola%2C%20vengo%20de%20su%20p%C3%A1gina%20web%21%20Me%20interesa%20saber%20informaci%C3%B3n" target="_blank" ></q-btn>
                </div>
              </div>
            </div>
            <div class="row text-white justify-center q-pt-xl gt-sm">
              Lállez © 2024
              <q-separator vertical size="3px" color="lallez-gold" spaced="10px"></q-separator>
              Todos los derechos reservados
              <q-separator vertical size="3px" color="lallez-gold" spaced="10px"></q-separator>
              Aviso de privacidad
            </div>
          </div>
          <div class="col-10 text-white q-pt-xl lt-md">
              <p>Lállez © 2024</p>
              <p>Todos los derechos reservados</p>
              <p>Aviso de privacidad</p>
            </div>
        </div>

      </q-page>
      <q-btn class="whatsapp-btn" type="a" href="https://wa.me/529991725855?text=%C2%A1Hola%2C%20vengo%20de%20su%20p%C3%A1gina%20web%21%20Me%20interesa%20saber%20informaci%C3%B3n" target="_blank" round padding="10px" color="green-13">
        <img src="/assets/iconos/png/whats_white.png" />
      </q-btn>
    </q-page-container>
  </q-layout>
</template>

<script>
import { ref } from 'vue'
import Swal from 'sweetalert2'
import axios from 'axios';

export default {
  name: 'LayoutDefault',
  setup() {

    const rightDrawerOpen = ref(false)
    const contact = ref({
      name: '',
      email: '',
      subject: '',
      phone: '',
      message: '',
    })

    let tab = ref("inicio")

    return {
      inicio: ref(null),
      nosotros: ref(null),
      servicios: ref(null),
      contacto: ref(null),
      leftDrawerOpen: ref(false),
      rightDrawerOpen,
      tab,
      services: [
        {
          id: 1,
          title: "<ul><li>Asesoramiento financiero personalizado.</li><li>Modelaje financiero y proyecciones.</li><li>Análisis de viabilidad y evaluación de riesgos.</li></ul>",
          img: "/assets/imagenes/side-view-business-man-calculating-finance-number2s.png",
          alt: "1. Asesoramiento financiero personalizado. 2. Modelaje financiero y proyecciones. 3. Análisis de viabilidad y evaluación de riesgos."
        },
        {
          id: 2,
          title: "<ul><li>Optimización de estructuras financieras.</li><li>Estrategias de financiamiento y captación de capital.</li><li>Desarrollo de planes de negocio y estrategias de crecimiento.</li></ul>",
          img: "/assets/imagenes/ingresar-salir-puden-ingresar-mil_0_27_1200_746.jpg",
          alt: "4. Optimización de estructuras financieras. 5. Estrategias de financiamiento y captación de capital. 6. Desarrollo de planes de negocio y estrategias de crecimiento."
        },
        {
          id: 3,
          title: "<ul><li>Gestión de tesorería y flujo de efectivo.</li><li>mplementación de sistemas de control financiero.</li></ul>",
          img: "/assets/imagenes/gente-negocios-irreconocible-sentada-reunion-graficos-mirando-senalando-tableta.jpg",
          alt: "7. Gestión de tesorería y flujo de efectivo. 8. Implementación de sistemas de control financiero."
        },
        {
          id: 4,
          title: "<ul><li>Asesoramiento en fusiones y adquisiciones.</li><li>Consultoría en gestión de riesgos financieros.</li></ul>",
          img: "/assets/imagenes/empresarios-elegantes-trajes-reunion-negocios-discutiendo-nuevo-proyecto-oficina.jpg",
          alt: "9. Asesoramiento en fusiones y adquisiciones. 10. Consultoría en gestión de riesgos financieros."
        }
      ],
      contact,
      sendLoading: ref(false),
      slide: ref('style'),
      panel: ref('nosotros'),
      parentHeight: 0
    }
  },
  mounted() {
      this.parentHeight = document.getElementById('q-page').style.minHeight;
  },
  methods: {
    toggleRightDrawer() {
      this.rightDrawerOpen = !this.rightDrawerOpen
    },
    onTabClick(id) {
      console.log(id)
      //this.tab = id
      this.leftDrawerOpen = false
      setTimeout(() => {
        this.$el.querySelector('#' + id).scrollIntoView({ behavior: 'smooth' });
      }, 100)
    },
    isMobile() {
      return window.innerWidth < 1024
    },
    onSubmit() {
        
        this.sendLoading = true
        axios.post('https://www.sonrisadeangle.com/api/contactar', {
          nombre: this.contact.name,
          email: this.contact.email,
          asunto: this.contact.subject,
          telefono: this.contact.phone,
          mensaje: this.contact.message,
        })
        .then(response => {
          if(response?.data?.ok){
            Swal.fire('Éxito', 'Agradecemos su confianza, en breve nos pondremos en contacto', 'success');
            this.contact = {
              name: '',
              email: '',
              subject: '',
              phone: '',
              message: '',
            }
          }else{
            Swal.fire('Ocurrió un error', response?.data?.message ? response?.data?.message : 'Intente de nuevo, por favor', 'error');
          }
        })
        .catch(error => {
          console.error(error)
          Swal.fire('Ocurrió un error', 'Intente de nuevo, por favor', 'error');
        })
        .finally(() =>{
          this.sendLoading = false
        })
      },
      validateEmail(email){
        return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      },
      validatePhone(val){
        if (val.match(/\d/g)) {
          return val.match(/^[1-9]{1}/)
        } else return false
      },

  }
}
</script>